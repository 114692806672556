<template>
  <div class="w-full">
    <div class="flex justify-between mb-4">
      <div class="w-1/3 text-left py-4 section_name">
        Change password
      </div>

      <div class="w-2/3">
        <div class="w-full flex justify-between">
          <div class="form_row w-full md:w-1/2 p-4">
            <label for="current_password" class="block text-gray-700 mb-2 text-left input_label">
              Current password <span>*</span>
            </label>
            <div class="relative">
              <input
                  @input="updateDisableButton"
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="current_password"
                  @click="skipErrors"
                  v-model="form.current_password"
                  :type="eye_off ? 'password' : 'text'"
                  placeholder="Current password">
              <div @click="eye_off = !eye_off"
                  class="eye py-1 px-2"
                  :class="eye_off ? 'eye_off' : 'eye_on'">
                <svg v-if="eye_off" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye-off"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
              </div>
            </div>
            <p v-if="errors.current_password" class="text-red-500 text-xs italic text-left py-2">
              This field is required
            </p>
            <p v-if="errors.old_password" class="text-red-500 text-xs italic text-left py-2">
              The current password does not match
            </p>
            <p v-if="errors.current_password_long" class="text-red-500 text-xs italic text-left py-2">
              Password must be at least 8 characters long
            </p>
          </div>
        </div>

        <div class="w-full flex justify-between">
          <div class="form_row w-full md:w-1/2 p-4">
            <label for="new_password" class="block text-gray-700 mb-2 text-left input_label">
              New password <span>*</span>
            </label>
            <div class="relative">
              <input
                  @input="updateDisableButton"
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="new_password"
                  @click="skipErrors"
                  v-model="form.new_password"
                  :type="eye_off_new ? 'password' : 'text'"
                  placeholder="New password">
              <div @click="eye_off_new = !eye_off_new"
                   class="eye py-1 px-2"
                   :class="eye_off_new ? 'eye_off' : 'eye_on'">
                <svg v-if="eye_off_new" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye-off"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
              </div>
            </div>
            <p v-if="errors.new_password" class="text-red-500 text-xs italic text-left py-2">
              This field is required
            </p>
            <p v-if="errors.new_password_long" class="text-red-500 text-xs italic text-left py-2">
              Password must be at least 8 characters long
            </p>
            <p v-if="errors.new_password_complexity" class="text-red-500 text-xs italic text-left py-2">
              Password too weak
            </p>
          </div>

          <div class="form_row w-full md:w-1/2 p-4">
            <label for="confirm_password" class="block text-gray-700 mb-2 text-left input_label">
              Confirm password <span>*</span>
            </label>
            <div class="relative">
              <input
                  @input="updateDisableButton"
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="confirm_password"
                  @click="skipErrors"
                  v-model="form.confirm_password"
                  :type="eye_off_new ? 'password' : 'text'"
                  placeholder="Confirm password">
              <div @click="eye_off_new = !eye_off_new"
                   class="eye py-1 px-2"
                   :class="eye_off_new ? 'eye_off' : 'eye_on'">
                <svg v-if="eye_off_new" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye-off"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
              </div>
            </div>
            <p v-if="errors.confirm_password" class="text-red-500 text-xs italic text-left py-2">
              This field is required
            </p>
            <p v-if="errors.confirm_password_not" class="text-red-500 text-xs italic text-left py-2">
              Passwords do not match
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full flex justify-end items-center py-6 px-2">
      <button
          @click="userUpdate"
          :disabled="update_disable"
          :class="update_disable ? 'bg-gray-400 border-gray-500' : 'bg-green-500 hover:bg-gray-200 hover:text-green-500 border-green-500'"
          class="this_button py-3 px-1 text-white border">
        Update
      </button>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapMutations} from "vuex";
import {infoMessage} from "../services/messages";

export default {
  name: "ChangePassword",
  data() {
    return {
      user: null,
      old_user_data: null,
      update_disable: true,
      eye_off: true,
      eye_off_new: true,
      error_message: '',
      form: {
        current_password: null,
        new_password: null,
        confirm_password: null,
      },
      errors: {
        old_password: false,
        current_password: false,
        current_password_long: false,
        new_password: false,
        new_password_long:false,
        new_password_complexity: false,
        confirm_password: false,
        confirm_password_not: false,
      }
    }
  },
  methods: {
    ...mapMutations([
      'setSearchSelectedOption',
      'loginUser'
    ]),
    getUser() {
      this.user = JSON.parse(localStorage.getItem('user')) || null;
    },
    closeSuccessModal() {
      this.skipErrors();
      this.skipForm();
      this.update_disable = true;
      this.$router.push('/account/profile');
    },
    validationPasswordComplexity(password) {
      const re = /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
      return re.test(String(password));
    },
    async formValidate() {
      if (!this.form.current_password) {
        this.errors.current_password = true;
        return false;
      } else {
        if (this.form.current_password.length < 8) {
          this.errors.current_password_long = true;
        }
      }
      if (!this.form.new_password) {
        this.errors.new_password = true;
        return false;
      } else {
        if (this.form.new_password.length < 8) {
          this.errors.new_password_long = true;
          return false;
        }
        if (!this.validationPasswordComplexity(this.form.new_password)) {
          this.errors.new_password_complexity = true;
          return false;
        }
      }
      if (!this.form.confirm_password) {
        this.errors.confirm_password = true;
        return false;
      }
      if (this.form.new_password !== this.form.confirm_password) {
        this.errors.confirm_password_not = true;
        return false;
      }

      return true;
    },
    async userUpdate() {
      let result = await this.formValidate();
      if (result) {
        let request_data = {
          current_password_digest: this.form.current_password,
          password_digest: this.form.new_password,
          password_confirm: this.form.confirm_password,
        };

        const response = await this.$http.post(`${this.$http.apiUrl()}/users/${this.user.id}/reset-password`, request_data)
            .catch(err => {
              const errors = err.response?.data?.message;
              if (typeof errors === 'string') {
                console.error(errors);
                return infoMessage(
                    errors || 'Oops. Something went wrong...',
                    this.getTopBanner ? 'error open_top_banner' :'error'
                );
              }
            });

        if (response.data?.statusCode && response.data?.statusCode > 300) {
          if (response.data?.message && response.data?.message === 'The old password did not match') {
            this.errors.old_password = true;
            this.update_disable = true;
            return;
          }

          return infoMessage(
              response.data?.message || 'Oops. Something went wrong...',
              this.getTopBanner ? 'error open_top_banner' :'error'
          );
        }

        infoMessage(
            `User password ${this.user.username} updated!`,
            this.getTopBanner ? 'success open_top_banner' : 'success'
        );

        this.closeSuccessModal();
      }
    },
    skipErrors() {
      for (let key in this.errors) {
        this.errors[key] = false;
      }
    },
    updateDisableButton() {
      this.update_disable = false;
      this.skipErrors();
    },
    skipForm() {
      this.form = {
        current_password: null,
        new_password: null,
        confirm_password: null,
      };
    },
  },
  async mounted() {
    await this.getUser();
  },
  computed: {
    ...mapGetters([
      'getSearchSelectedOption',
      'getTopBanner'
    ]),
  },
  components: {},
  watch: {},
}
</script>
<style lang="scss" scoped>
.form_label {
  color: #22292f;
  font-size: 1.17em;
  font-weight: 600;
}
.section_name {
  color: #22292f;
  font-size: 1.17em;
  font-weight: 700;
}
.input_label {
  color: #22292f;
  font-size: 1em;
  font-weight: 600;
  span {
    color: #28a745;
  }
}
.span_in_select {
  color: #6c757d !important;
}
.this_button {
  font-size: 1.17em;
  font-weight: 700;
  border-radius: 5px;
  width: 64px;
}
.search-dropdown {
  position: absolute;
  width: 85%;
  left: 5px;
  top: 6px
}
.eye {
  position: absolute;
  top: 0;
  right: 0;
}
.eye_off {
  color: #6c757d;
}
.eye_on {
  color: #28a745;
}
</style>